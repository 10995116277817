<template>
    <div>
        <el-button class="select-file-btn" v-if="!isSelect && image === ''" plain @click="dialogVisible = true"
            size="mini">选择海报</el-button>
        <div class="select-file-info" v-if="image !== ''">
            <div class="select-file-info-name">
                <el-image style="width: 100%; height: 100%" :src="image" :preview-src-list="[image]" fit="contain">
                </el-image>
            </div>
            <div class="select-file-info-option">
                <el-link type="primary" @click="reelectFile">重选</el-link> -
                <el-link type="primary" @click="deleteFile">删除</el-link>
            </div>
        </div>

        <el-dialog title="选择文件" :visible.sync="dialogVisible" style="overflow: hidden" width="700px" append-to-body
            :before-close="handleClose">
            <div slot="title">
                海报模板
            </div>

            <div class="file-content">
                <div class="search-data">
                    <el-select v-model="type" placeholder="类型" size="mini" @change="getList">
                        <el-option label="课程" :value="1"></el-option>
                        <el-option label="课程计划" :value="2"></el-option>
                    </el-select>
                </div>
                <div class="image-list-content">

                    <div class="image-list" v-loading="loading">
                        <div class="image-item" v-for="file in files" v-bind:key="file.id" @click="onSelectFile(file)">
                            <span v-if="selectFileId == file.id" class="select-icon"></span>
                            <div class="image-item-top">
                                <el-image style="width: 100%; height: 100%" :src="imageUrl + file.pic" fit="contain">
                                </el-image>

                            </div>
                        </div>

                        <div class="search">

                            <div class="page fl-r">
                                <el-pagination small background :current-page="page" :page-sizes="[9, 18]"
                                    :page-size="pageSize" layout="total, prev, pager, next " @size-change="setPageSize"
                                    @current-change="setPage" :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
                <el-button type="primary" @click="select" size="mini">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "selectImages",
    props: {
        image: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            type:2,
            isSelect: false,
            imageUrl: config.imageUrl,
            dialogVisible: false,
            files: [],
            name: '',
            page: 1,
            pageSize: 9,
            total: 0,
            loading: true,
            selectFileId: 0,
            selectFileName: '',
            selectFile: {},
            uploadUrl: '',
            uploadData: {},
            uploading: false
        }
    },
    methods: {
        ...mapActions('poster', ['getPosterList']),
        select() {
            if (this.selectFileId == 0) {
                this.$message.error('请选择文件');
                return
            }
            this.dialogVisible = false
            this.isSelect = true
            this.$emit('onSelect', this.selectFile)
        },
        handleClose(done) {
            done();
        },
        setPageSize(pageSize) {
            this.pageSize = pageSize
            this.getList()
        },
        setPage(page) {
            this.page = page
            this.getList()
        },
        onSelectFile(file) {
            this.selectFileId = file.id
            this.selectFileName = file.name
            this.selectFile = file
        },
        getList() {
            this.loading = true
            let data = {
                page: this.page,
                pageSize: this.pageSize,
                type:this.type,
            }
            if (this.name != '') {
                data.name = this.name
            }
            this.getPosterList(data).then(res => {
                this.files = res.data.list
                this.total = res.data.total
                this.loading = false
            })
        },

        reelectFile() {
            this.dialogVisible = true
        },
        deleteFile() {
            this.isSelect = false
            this.$emit('onDelete')
        },
    },
    mounted() {
        this.getList()
    }
}
</script>

<style>
.select-file-info {
    padding: 20px;
    background-color: #fafafa;
    width: 160px;
}

.select-file-info-name {
    width: 100%;
    color: #333;
    line-height: 20px;
    font-size: 12px;
    word-break: break-all
}

.select-file-info-option {}

.el-divider__text,
.el-link {
    font-size: 12px;
}

.el-dialog__header {
    padding: 20px;
}

.el-dialog__body {
    padding: 0
}

.file-content {
    height: 528px;
    border-bottom: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
    position: relative;
    overflow: hidden;
}

.all-file {
    padding: 20px 10px;
    float: left;
    height: 490px;
    overflow-y: scroll;
}

.selected {
    background: #f7f7f7;
}

.file-tag {
    height: 32px;
    color: #333;
    font-size: 12px;
    padding: 0 10px;
    line-height: 32px;
    cursor: pointer;
    color: #999;
}

.file-count {
    margin-left: 5px;
    font-size: 12px;
}

.search {
    width: 100%;
    flex-grow: 2;
    position: absolute;
    background: #fff;
    bottom: 0;
    width: 100%;
    padding: 10px;
    left: 0;
}

.search-data {
    width: 100%;
    padding: 10px;
}

.image-list-content {
    height: calc(100% - 52px);
    overflow: hidden;
}

.image-list {
    padding: 20px 10px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    overflow: auto;
}

.el-card__body {
    padding: 10px
}

.select-icon {
    z-index: 99;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 30px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAABNChwpAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAadJREFUSInF1z1PFEEYAOAHEBIbf8FBQiF/gQIVExYj8Y6Y0HhGqSwEWwu1l2gvhsRGYiRobEDjBxhjZaGUYEzESKWVRqMFCeBZDAYT9nJ7y5J5y5mdeZ/dmZ13t8Vk7RquixStmMAl/IkFgNsYxWYsANzHCNZjAWAep/ArFgBeIcH3IhOd6OTnhWwAeIt+fC0ieVLiRYXLb7IDYBlH8XkvyQc7WRzmzAJ33jcHgE84gpShO/G8zLme3e0DJRYqVBd5sJo+thEAvuAYlupdMLfGvYTT3TttSYmXwyH57Mf6k7eYrGUwgEOYw/G0zpNdPCvT+4jfG6xUw2Ovd+d5AHAQD1FO60xKYb2h8pQna40nbBYA7ZhGNa3z7GE62rj7IdtkeQDQhlu4mGfw/5FlE6bFFsZxMxYAariCq7EA/+IGxuQs50UAYArnsRELADNylPMiAfAYQ5oo50UD4DUG8C0WAN7JWM73CwAr6NOgnO8nwHbyvm1MFABhGfqFr6woAMKGTIQNGgVAeDWHhFc1CoBwSI0I/yBRAITjelQ4vh2IACAUrjH8+Av5AmCiEP+iwQAAAABJRU5ErkJggg==);
}

.image-item {
    width: 216px;
    height: 120px;
    margin: 5px;
    cursor: pointer;
    position: relative;
    border: 1px solid #ebebeb;
    line-height: 1;
}

.file-icon {
    float: left;
    width: 50px;
    height: 50px;
}

.file-info {
    width: 100%;
    height: 100%;
}

.image-item-top {
    width: 100%;
    height: 100%;
}

.file-info-title {
    color: #333;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 20px;
    width: 170px;
    height: 20px;
    overflow: hidden;
}

.file-info-size {
    color: #999;
    font-size: 12px;
}

.file-option {
    height: 36px;
    background: #fafafa;
    border-top: 1px solid #ebebeb;

}

.clear {
    clear: both;
}
</style>
